export const environment = {
  production: true,
  baseUrl: 'https://fete24.ro',
  apiUrl: 'https://rofete24api.com',
  recaptcha: {
    siteKey: '6Ldin8coAAAAAKhjYbNWqzYpmZNfN_dMmhwlbdcL',
  },
  stripeLink_advert_price_5: 'https://buy.stripe.com/28ocQy0ys1kA1Lq6ou',
  stripeLink_advert_price_6: 'https://buy.stripe.com/14k2bUcha3sIfCgeUX',
  stripeLink_advert_price_12: 'https://buy.stripe.com/6oE17Qa92aVaahWbIM',

  facebookUrl: 'https://www.facebook.com/profile.php?id=61554729203708',
  instagramUrl: 'https://www.instagram.com/fete24.ro/?next=%2F'
};
